import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const Aside = makeShortcode("Aside");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Your opportunity to make a solid first impression and visually express your purpose.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2K3eIAP/xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIQESH/2gAIAQEAAQUC6N5UHsRH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAECH/2gAIAQEABj8Ca//EABwQAAICAgMAAAAAAAAAAAAAAAABETEQIUFRYf/aAAgBAQABPyHhT7GPceLDFPZlxOyh/9oADAMBAAIAAwAAABDr3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB4QAQACAgEFAAAAAAAAAAAAAAEAETFBIVFhcaGx/9oACAEBAAE/EEvYF2cHWpzlEFqto7b0Y3C3DdcPncYyzLAFQYPJe57F+z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9ef3646da81e5474e13f73ed0802ba43/5f1c3/logo-book.webp 288w", "/static/9ef3646da81e5474e13f73ed0802ba43/c4eb6/logo-book.webp 576w", "/static/9ef3646da81e5474e13f73ed0802ba43/53334/logo-book.webp 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9ef3646da81e5474e13f73ed0802ba43/58ca1/logo-book.jpg 288w", "/static/9ef3646da81e5474e13f73ed0802ba43/7fee0/logo-book.jpg 576w", "/static/9ef3646da81e5474e13f73ed0802ba43/c3413/logo-book.jpg 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9ef3646da81e5474e13f73ed0802ba43/c3413/logo-book.jpg",
              "alt": "Logo design - Magnus Code - Malaysia",
              "title": "Logo design - Magnus Code - Malaysia",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
        <Caption fullWidth mdxType="Caption">Logo design process.</Caption>
        <Row mdxType="Row">
  Strengthen your brand experience and perception by having your very own
  high-quality logo specially designed by Magnus Code logo design services.
        </Row>
        <Row mdxType="Row">
  Our comprehensive design solutions allow you to represent your brands’ vision
  and identity through various combination of text and imagery. With high
  emphasis on quality and aesthetics, Magnus Code allows you to have a logo that
  will ensure continuous brand loyalty.
        </Row>
        <Row mdxType="Row">
  Our team of professional designers will guide you through various stages of
  logo production to ensure you end up satisfied with one of the most important
  branding investments you can make for your organization.
        </Row>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Logo Design.`}</strong></p>
          <p>{`Good logo design not only looks professional, it also signifies something deeper.`}</p>
        </Aside>
      </Column>
    </Row>
    <Row className="resource-card-group mt-4 " mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Talk to our team" title="Contact Us" aspectRatio="2:1" actionIcon="arrowRight" href="/contact" color="light" className="blue-card" mdxType="ResourceCard"></ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      